.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer {
    padding: 1rem 2rem;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    gap:4rem;
}
.social-links {
    display: flex;
    gap:4rem;
}
.social-links>img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img {
    width: 10rem;
}
#blur-f-1{
    bottom:0;
    right:15%;
    width: 26rem;
    height: 12rem;
    filter:blur(150px);
    background:red
}
#blur-f-2 {
    bottom:0;
    left:15%;
    width: 26rem;
    height: 12rem;
    filter:blur(200px);
    background:rgb(255,115,0);
}
@media screen and (max-width:768px) {
    .footer-container {
        transform: scale(0.9);
    }
}