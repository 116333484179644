.join {
    display: flex;
    padding: 0 2rem;
    gap:10rem
}
.left-j{
    font-size: 3rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    position:relative

}
.left-j>hr{
    position:absolute;
    width:10.5rem;
    border:2px solid var(--orange);
    border-radius: 20%;
    margin:-10px 0
}
.left-j>div{
    display: flex;
    gap:1rem
}
.right-j {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.email-container {
    display: flex;
    gap:3rem;
    background-color:gray;
    padding:1rem 2rem;
}
.email-container>input {
    background:transparent;
    outline: none;
    border:none;
    color:var(--lightgray);
    width:12rem
}
.email-container>button{
    background-color: var(--orange);
    color:white
}
::placeholder{
    color:var(--lightgray)
}
.right-j>div>button {
    background:orange;
    color:white
}
@media screen and (max-width:768px) {
    .join {
        flex-direction: column;
        gap:0.5rem;
        margin-bottom:-3rem;
    }
    .left-j {
        font-size:x-large;
        flex-direction: column;

    }
    .right-j{
        padding: 2rem;
        align-self: center;
    }
    .email-container {
        transform:scale(0.8);
    }
}